<template>
    <component :is="as" ref="elementRef" @click="doConfirm" :class="className"   
        :style="{ 'cursor': !isConfirming ? 'pointer' : 'inherit' }">
        <div class="spinner-border spinner-border-sm me-1" role="status" v-if="isConfirming && as === 'button'" >
            <span class="sr-only"></span>
        </div>
        <slot :isConfirming="isConfirming">
            <template v-if="as !== 'i' && as !== 'img'">
                {{ $t('Confirm') }}
            </template>
        </slot>
    </component>   
</template>

<script setup lang="ts">
import $t from 'o365.modules.translate.ts';
import { useAttrs, computed, ref } from 'vue';
import { getDataObjectById } from 'o365.vue.ts';

export interface IProps {
    dataObject?: any,
    row?: any,
    confirmOptions?: any
    as?: any
};

export interface IEmits {
    (e: 'confrim', pResult: any, pRow?: any): void,
    (e: 'cancel', pResult: any | Error, pRow?: any): void,
}

const props = withDefaults(defineProps<IProps>(), {
    as: 'button'
});

const emit = defineEmits<IEmits>();

function o365_confirm(...args: Parameters<typeof confirm>) {
    return import('o365.controls.confirm.ts').then(services => services.default(...args));
}

const attrs = useAttrs();
const elementRef = ref(null);

const className = computed(() => {
    return attrs.class ? '' : 'btn btn-sm btn-link';
});

const _dataObject = computed(() => {
    if (props.dataObject) {
        return props.dataObject
    } else if (props.row) {
        const dataObjectId = props.row.dataObjectId;
        const appId = props.row.appId;
        if (dataObjectId) {
            return getDataObjectById(dataObjectId, appId);
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
});

const _row = computed(() => {
    return props.row ?? _dataObject.value?.current;
});

const isConfirming = ref(false);

async function doConfirm() {
    isConfirming.value = true;
    try {
        const result = await o365_confirm({
            message: $t('Confrim'),
            ...props.confirmOptions ?? {}
        });
        emit('confrim', result, _row.value);
    } catch (ex) {
        emit('cancel', ex, _row.value);
    } finally {
        isConfirming.value = false;
    }
}
</script>